<template>
  <b-link
    :href="file.link"
    target="_blank"
    class="custom-link"
  >
    {{ file.filename }}
  </b-link>
</template>

<script>
export default {
  name: 'FileLink',
  props: {
    file: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
